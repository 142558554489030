import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Pagination } from '../shares/pagination/pagination';
import { SearchFilter } from './search-filter-behavior';

@Injectable()
export abstract class Unsubscribe implements OnDestroy {
  unsubscribe$: Subject<void> = new Subject<void>();
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
