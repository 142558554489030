import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'staffFullName'
})
export class StaffFullNamePipe implements PipeTransform {
  transform(value: any): string {
    let fullName = [];
    if (!value) return '';

    if (value.titles?.name) {
      fullName.push(value.titles.name);
    }

    if (value.last_name) {
      fullName.push(value.last_name);
    }

    if (value.first_name) {
      fullName.push(value.first_name);
    }

    return fullName.join(' ');
  }
}
