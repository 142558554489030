import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { map } from 'rxjs/operators';
import { LocalStorageService } from './local-storage.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageEnum } from '../models/enums/local-storage.enum';
import { NavigationStart, Router } from '@angular/router';
import { Login } from '../models/login';
import { AuthVerification } from '../models/auth-verification';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authChange$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.isAuth);
  authStatus: boolean = this.isAuth;
  constructor(
    private router: Router,
    private requestService: RequestService,
    private localStorageService: LocalStorageService
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.authStatus != this.isAuth) {
          this.markStatusChange();
        }
      }
    });
  }

  login(data: { username: string; password: string }): Observable<Login> {
    return this.requestService.postJSON<Login>('/student/account/login', { data }).pipe(
      map(res => {
        this.localStorageService.set(LocalStorageEnum.token, res.token);
        this.localStorageService.set(LocalStorageEnum.refresh_token, res.refresh_token);
        this.localStorageService.set(LocalStorageEnum.user_id, res.user._id!);
        this.markStatusChange();
        return res;
      })
    );
  }

  verifyEmail(data: { email_token: string; verification: string }): Observable<any> {
    return this.requestService.postJSON<any>('student/account/verify', { data }).pipe(
      map(res => {
        this.localStorageService.set(LocalStorageEnum.token, res.user.token);
        this.localStorageService.set(LocalStorageEnum.refresh_token, res.user.refresh_token);
        this.localStorageService.set(LocalStorageEnum.user_id, res.user._id!);
        this.markStatusChange();
        return res;
      })
    );
  }

  redirectToHome(data: { token: string; refresh_token: string; _id: string }): void {
    this.localStorageService.set(LocalStorageEnum.token, data.token);
    this.localStorageService.set(LocalStorageEnum.refresh_token, data.refresh_token);
    this.localStorageService.set(LocalStorageEnum.user_id, data._id!);
    this.markStatusChange();
  }

  register(data: User) {
    return this.requestService.postJSON<{ user: User }>('student/account/username', { data });
  }

  resendEmailVerificationCode(data: { email_token: string }) {
    return this.requestService.postJSON<AuthVerification>('student/account/resend_verification', { data });
  }

  requestResetPassword(data: { email: string }) {
    return this.requestService.postJSON<AuthVerification>('student/account/request_reset_password', { data });
  }

  verifyResetPassword(data: { email_token: string; verification: string }) {
    return this.requestService.postJSON<AuthVerification>('student/account/verify_reset_password', { data });
  }

  resetPasswordWithEmail(data: { password_token: string; new_password: string }) {
    return this.requestService.postJSON<AuthVerification>('student/account/reset_password', { data });
  }

  resendResetPwdVerficationCode(data: { email_token: string }) {
    return this.requestService.postJSON<AuthVerification>('student/account/resend_reset_verification', { data });
  }

  signInOrSingUpWithGoogle(data: { google_token: string, device_os?: string, device_name?: string }, profile_image?: string) {
    return this.requestService.postJSON<{ refresh_token: string; token: string; user: User }>('/student/account/login_google', { data }).pipe(
      map(res => {
        this.localStorageService.set(LocalStorageEnum.token, res.token);
        this.localStorageService.set(LocalStorageEnum.refresh_token, res.refresh_token);
        this.localStorageService.set(LocalStorageEnum.user_id, res.user._id!);
        this.markStatusChange()
        return res;
      })
    );
  }

  signInOrSignUpWithFacebook(data: { facebook_id: string, facebook_token: string, device_os?: string, device_name?: string }, profile_image?: string) {
    return this.requestService.postJSON<{ refresh_token: string; token: string; user: User }>('/student/account/login_facebook', { data }).pipe(
      map(res => {
        this.localStorageService.set(LocalStorageEnum.token, res.token);
        this.localStorageService.set(LocalStorageEnum.refresh_token, res.refresh_token);
        this.localStorageService.set(LocalStorageEnum.user_id, res.user._id!);
        this.markStatusChange()
        return res;
      })
    )
  }

  logout(): Observable<string> {
    this.localStorageService.delete(LocalStorageEnum.token);
    this.localStorageService.delete(LocalStorageEnum.refresh_token);
    this.localStorageService.delete(LocalStorageEnum.user_id);
    this.localStorageService.delete(LocalStorageEnum.permissions);
    return new Observable<string>(observer => {
      observer.complete(); // complete function will be called when the observable is complete
      this.markStatusChange();
    });
  }

  private markStatusChange() {
    this.authChange$.next(this.isAuth);
    this.authStatus = this.isAuth;
  }

  get isAuth(): boolean {
    return this.localStorageService.get(LocalStorageEnum.token) ? true : false;
  }
}
