import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContainerComponent } from './components/container/container.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '',
    component: ContainerComponent,
    children: [
      {
        path: 'home',
        loadChildren: () => import('./routes/home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'exam',
        loadChildren: () => import('./routes/exam/exam.module').then(m => m.ExamModule)
      },
      {
        path: 'announcement',
        loadChildren: () => import('./routes/announcement/announcement.module').then(m => m.AnnouncementModule)
      },
      {
        path: 'contact-us',
        loadChildren: () => import('./routes/contact-us/contact-us.module').then(m => m.ContactUsModule)
      },
      {
        path: 'setting',
        loadChildren: () => import('./routes/setting/setting.module').then(m => m.SettingModule)
      },
      {
        path: 'apply-for-exam',
        loadChildren: () => import('./routes/apply-for-exam/apply-for-exam.module').then(m => m.ApplyForExamModule)
      },
      {
        path: 'assessment-center',
        loadChildren: () => import('./routes/assessment-center/assessment-center.module').then(m => m.AssessmentCenterModule)
      },
      {
        path: 'my-exam',
        // canActivate: [],
        loadChildren: () => import('./routes/my-exam/my-exam.module').then(m => m.MyExamModule)
      },
      {
        path: 'login',
        loadChildren: () => import('./routes/login/login.module').then(m => m.LoginModule)
      },
      {
        path: 'auth',
        loadChildren: () => import('./routes/authentication/authentication.module').then(m => m.AuthenticationModule)
      },
      {
        path: 'policy',
        loadChildren: () => import('./routes/policy/policy.module').then(m => m.PolicyModule)
      },
    ]
  },
  {
    path: 'validate',
    loadChildren: () => import('./routes/validation/validation.module').then(m => m.ValidationModule)
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
