<!-- eslint-disable @angular-eslint/template/accessibility-elements-content -->
<div class="app-menu" (window:resize)="onResize()">
  <mat-sidenav-container class="app-container" autosize>
    <mat-sidenav
      *ngIf="mobileQuery"
      #sidenav
      class="example-sidenav"
      [class.large-width]="mobileQuery"
      [mode]="mobileQuery ? 'over' : 'side'"
      [opened]="!mobileQuery"
      [disableClose]="!mobileQuery"
    >
      <div class="main-logo" routerLink="/">
        <div class="logo-container">
          <img [src]="'assets/imgs/mlvt-logo.svg'" width="65px" alt="logo-image" />
        </div>
      </div>
      <mat-nav-list class="menu-list mt-0" id="list-nav">
        <div *ngFor="let m of menu">
          <mat-list-item
            *ngIf="m.child.length <= 0 "
            [routerLink]="m.route"
            [routerLinkActive]="m.route ? 'active' : ''"
            [routerLinkActiveOptions]="{exact:false}"
          >
            <span class="menu-item-title">{{ m.title | translate}}</span>
            <mat-icon
              mat-list-icon
              *ngIf="m.svgIcon !== '' "
              [svgIcon]="m.svgIcon"
              [class.svg-active]="m.route && router.url.includes(m.route)"
            >
            </mat-icon>
          </mat-list-item>
        </div>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
      <div class="example-sidenav-content app-root-content">
        <mat-toolbar class="dashboard-toolbar" [class.blurred]="!isFirefox">
          <header class="dashboard__header">
            <button *ngIf="mobileQuery" class="icon" mat-icon-button (click)="toggleSideNav()">
              <mat-icon svgIcon="menu-hamburger"></mat-icon>
            </button>

            <div class="main-logo" routerLink="/">
              <div class="logo-container">
                <img [src]="'assets/imgs/mlvt-logo.svg'" width="65px" alt="logo-image" />
              </div>
            </div>

            <mat-nav-list class="nav-horizontal" *ngIf="!mobileQuery">
              <div *ngFor="let m of menu">
                <mat-list-item
                  *ngIf="m.child.length <= 0 "
                  [routerLink]="m.route"
                  [routerLinkActive]="m.route ? 'active' : ''"
                  [routerLinkActiveOptions]="{exact:false}"
                >
                  <span class="menu-item-title">{{ m.title | translate}}</span>
                </mat-list-item>
              </div>
            </mat-nav-list>

            <div class="header-action">
              <div *ngIf="!isAuth" class="action">
                <button mat-button mat-button color="primary" [routerLink]="['/auth/signup']">{{'button.sign_up' | translate}}</button>
                <button mat-flat-button color="primary" [routerLink]="['/login']">
                  {{'button.log_in' | translate}}
                </button>
              </div>
              <div *ngIf="isAuth" class="avatar">
                <button mat-button [matMenuTriggerFor]="beforeMenu" class="profile-menu-btn">
                  <img
                    mat-card-avatar
                    class="avatar-profile"
                    alt="avatar"
                    [src]="account?.profile_image ? (account?.profile_image | staticFile) : (account?.gender === 'female' ? 'assets/imgs/female.svg' : 'assets/imgs/male.svg')"
                  />
                  <span class="profile-info">
                    {{(account | staffFullName) || 'Unknown'}}
                  </span>
                  <mat-icon>arrow_drop_down</mat-icon>
                </button>
                <mat-menu #beforeMenu="matMenu" xPosition="before">
                  <button mat-menu-item [routerLink]="['/setting/manage-staff',account?._id,'detail']">
                    <mat-icon>settings</mat-icon>
                    {{'menu.setting' | translate}}
                  </button>
                  <button mat-menu-item (click)="logout()"><mat-icon>logout</mat-icon>{{'button.logout' | translate}}</button>
                </mat-menu>
              </div>
            </div>
          </header>
        </mat-toolbar>
        <!-- <mat-toolbar *ngIf="account?.student_exam_states" class="reminder" [ngClass]="{payment-success: payment-success}">
          <span *ngIf="account?.student_exam_states?.status == ExamStateEnum.PRETEST" class="exam-state">
            Please Complete
            Your Pre-Test. <a [routerLink]="['/exam', account?.student_exam_states?.exam_dates]">Continue</a>
          </span>
          <span *ngIf="account?.student_exam_states?.status == ExamStateEnum.PAYMENT" class="exam-state">
            Please Complete
            Your Payment. <a [routerLink]="['/apply-for-exam', 'personal_info']">Continue</a>
          </span>
          <span *ngIf="account?.student_exam_states?.status == ExamStateEnum.QUALIFICATION_TEST"
            class="exam-state">Please Complete Your Qualification Test. <a
              [routerLink]="['/exam', account?.student_exam_states?.exam_dates]">Continue</a>
          </span>
        </mat-toolbar> -->
        <ng-container *ngIf="account?.student_exam_states && !isCloseReminder">
          <mat-toolbar class="reminder">
            <section *ngIf="account?.student_exam_states?.status !== 6 && account?.student_exam_states?.status !== -1">
              <span class="exam-state">
                {{'menu.please_complete' | translate}}
                <a
                  [routerLink]="['/exam', account?.student_exam_states?.qualification_titles?.occupations?._id, 'choose-skill']"
                  >{{'button.click_here' | translate}}</a
                >
              </span>
            </section>
            <button
              mat-stroked-button
              class="btn-sm btn-close-reminder"
              #tooltip="matTooltip"
              matTooltip="Close"
              matTooltipPosition="right"
              (click)="isCloseReminder = true"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-toolbar>
        </ng-container>

        <section class="dash-row" [ngClass]="{'standard-container': !isFullWidth}">
          <router-outlet></router-outlet>
        </section>
        <ng-container *ngTemplateOutlet="footer"></ng-container>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<ng-template #footer>
  <footer id="footer">
    <div class="footer-wrapper">
      <div class="grid gap-16 col-md-2 col-lg-3 part-wrapper">
        <section class="flex gap-16">
          <div class="logo-container">
            <img [src]="'assets/imgs/mlvt-logo.svg'" width="50" alt="logo-image" />
          </div>
          <div>
            <div class="title">{{'footer.competency_based' | translate}}</div>
            <div class="mb-16">
              {{'footer.the_competency' | translate}}
            </div>
            <a href="https://www.facebook.com/TVETNews" target="_blank">
              <mat-icon svgIcon="facebook"></mat-icon>
            </a>
          </div>
        </section>
        <section class="contact">
          <span class="sub-title">{{'footer.contact' | translate}}</span>
          <section class="mt-16 mb-12 flex gap-8">
            <mat-icon svgIcon="phone"></mat-icon>
            <div>
              <a>{{'footer.phone' | translate}}</a>
              <div>+(855) 096 777 8975</div>
            </div>
          </section>
          <section class="mt-12 mb-12 flex gap-8">
            <mat-icon svgIcon="calendar"></mat-icon>
            <div>
              <a>{{'footer.working_hours' | translate}}</a>
              <div>{{'footer.work_time' | translate}}</div>
            </div>
          </section>
          <section class="mt-12 flex gap-8">
            <mat-icon svgIcon="location"></mat-icon>
            <div>
              <a>{{'footer.location' | translate}}</a>
              <div>{{'footer.location_name' | translate}}</div>
            </div>
          </section>
        </section>
        <section>
          <div class="mb-16">
            <span class="sub-title">{{'footer.prepared_by' | translate}}</span>
            <div class="flex gap-16 mt-16">
              <img src="assets/imgs/organizer.png" width="50" alt="" />
              <div>
                <section class="mb-6">{{'footer.ministry' | translate}}</section>
                <section>{{'footer.and_vocational' | translate}}</section>
              </div>
            </div>
          </div>

          <div>
            <span class="sub-title">{{'footer.funded' | translate}}</span>
            <div class="mt-16"><img src="assets/imgs/sponsor.jpeg" width="150" alt="" /></div>
          </div>
        </section>
      </div>
      <div class="mt-24 mb-24">
        <section class="border col-span-3"></section>
      </div>
      <div class="footer-bottom flex">
        <span>Copyright © {{dateNow | date: 'yyyy'}} - {{'footer.all_rights' | translate}}</span>
        <div>
          <button mat-button routerLink="policy">
            <a>Privacy Policy</a>
          </button>
          <button mat-button [matMenuTriggerFor]="dropMenu" class="profile-menu-btn">
            <mat-icon class="language">language</mat-icon>
            <span>
              {{ browserLang == 'km'  ? 'ខ្មែរ' : 'English'}}
            </span>
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
          <mat-menu #dropMenu="matMenu">
            <button mat-menu-item *ngFor="let lang of langs | keyvalue" (click)="useLanguage(lang.key)">
              <mat-icon svgIcon="{{lang.key == 'km' ? 'khmer_flag' : 'english_flag'}}"></mat-icon>
              <span>{{ lang.value }}</span>
              <span *ngIf="browserLang == lang.key" class="pl-8"><mat-icon svgIcon="check"></mat-icon></span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </footer>
</ng-template>
