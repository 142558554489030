import { Injectable, Injector } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Quiz } from '../models/quiz';
import { User } from '../models/user';
import { AuthService } from './auth.service';
import { BaseCrudService } from './base-crud.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends BaseCrudService<User> {
  public staffId: string | undefined;

  constructor(injector: Injector, private authService: AuthService) {
    super(injector);
    this.path = '/student/account';
  }

  getAccountInfo(): Observable<User> {
    return this.requestService.getJSON(this.path + '/info');
  }

  getUpdateInfo(): Observable<User> {
    return this.requestService.getJSON<User>(this.path + '/info').pipe(
      map(res => {
        this.authService.authChange$.next(true);
        return res;
      })
    );
  }

  changePassword(data: { old_password: string; new_password: string }) {
    return this.requestService.postJSON(this.path + '/change_password', { data });
  }

  updateMyInfo(data: User) {
    return this.requestService.patchFile<User>(this.path + '/info', { data }).pipe(
      map(res => {
        this.authService.authChange$.next(true);
        return res;
      })
    );
  }

  checkExistedUser(data: any) {
    return this.requestService.getJSON(this.path + '/check_existed_user', { data });
  }

  linkEmailRequest(data: { email: string }) {
    return this.requestService.postJSON(this.path + '/link_email_request', { data: data, is_loading: true });
  }

  resendEmailVerification(data: { email_token: string }) {
    return this.requestService.postJSON(this.path + '/resend_link_email_verification', {
      data: data,
      is_loading: true
    });
  }

  linkEmailAccount(data: { email_token: string; verification: string }) {
    return this.requestService.postJSON(this.path + '/link_account', { data: data, is_loading: true });
  }

  requestResetPassword(data: { email: string }) {
    return this.requestService.postJSON(this.path + '/request_reset_password', { data: data, is_loading: true });
  }

  resendResetVerification(data: { email_token: string }) {
    return this.requestService.postJSON(this.path + '/resend_reset_verification', { data: data, is_loading: true });
  }

  verifyResetPassword(data: { email_token: string; verification: string }) {
    return this.requestService.postJSON(this.path + '/verify_reset_password', { data: data, is_loading: true });
  }

  resetPassword(data: { password_token: string; new_password: string }) {
    return this.requestService.postJSON(this.path + '/reset_password', { data: data, is_loading: true });
  }

  accountStatus(): Observable<{ student_quizzes: Quiz }> {
    return this.requestService.getJSON(this.path + '/status');
  }

  addUsername(data: { username: string; password?: string }) {
    return this.requestService.postJSON(this.path + '/add_username', { data });
  }

  addEmail(data: { email: string, password?: string }) {
    return this.requestService.postJSON(this.path + '/add_email', { data });
  }

  verifyAddEmail(data: { token: string }) {
    return this.requestService.postJSON(this.path + '/verify_add_email', { data });
  }

  addGoogleAccount(data: { google_token?: string }) {
    return this.requestService.postJSON(this.path + '/add_google', { data });
  }

  addFacebookAccount(data: { facebook_id?: string, facebook_token?: string; }) {
    return this.requestService.postJSON(this.path + '/add_facebook', { data });
  }
}
