export default class EnumConstant {
  public static readonly ACTIVE = 1;
  public static readonly INACTIVE = 0;
  public static readonly DISABLED = -2;
  public static readonly APPROVED = 1;
  public static readonly REQUESTED = 4;
  // public static readonly PENDING = 5;
  public static readonly REJECTED = -3;
  public static readonly DELETE = -9;
  public static readonly CANCEL = 9;

  public static readonly PUBLIC = 1;
  public static readonly DRAFT = 2;
  public static readonly DISABLE_OWN = -1;
  public static readonly REQUESTING = 3;
  public static readonly REJECT = -3;
  public static readonly UNPUBLISHED = -1;

  public static readonly Attendance = {
    PRESENT: 1,
    ABSENT: 2,
    PERMISSION: 3,
    LATE: 4
  };

  public static readonly Gender = {
    MALE: 'male',
    FEMALE: 'female'
  };

  public static readonly Enrolment = {
    NA: 0,
    PASSED: 1,
    FAILED: -1,
    DELETE_NAME: -8
  };

  public static readonly ReadPermission = {
    PUBLIC: 1,
    PRIVATE: -1
  };

  public static readonly MaritalStatus = {
    SINGLE: 'single',
    MARRIED: 'married',
    DIVORCED: 'divorced'
  };

  public static readonly ExamState = {
    FAILED: -1,
    INCOMPLETE: 0,
    PASSED: 1,
    PRETEST: 2,
    PAYMENT: 3,
    QUALIFICATION_TEST: 4,
    PRACTICE: 5
  };
}

export enum Role {
  ALL = 'all',
  ADMIN = 'admin',
  SCHOOL = 'school',
  TEACHER = 'teacher',
  STUDENT = 'student',
  FACULTY = 'faculty',
  PARENT = 'parent',
  OFFICER = 'officer',
  DEPARTMENT = 'department'
}

export enum RoleId {
  ADMIN = 1,
  SCHOOL = 2,
  TEACHER = 3,
  STUDENT = 4,
  FACULTY = 5,
  DEPARTMENT = 6,
  PARENT = 7
}

export enum AcademicType {
  SHORT_COURSE = 'short_course',
  PROGRAM = 'program'
}

export enum Announcement {
  MINISTRY = 'ministry',
  SCHOOL = 'school',
  STUDENT = 'student',
  CLASS = 'class'
}

export enum ClassSubjectStatus {
  DEFAULT = 1,
  ADDED = 0
}

export enum WeekDay {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 7
}

export enum Attendance {
  PRESENT = 1,
  ABSENT = 2,
  PERMIT = 3,
  LATE = 4
}

export enum UserStatusEnum {
  active = 1,
  pending = 0,
  inactive = -1,
  disable = -2
}

export enum HistoryActionEnum {
  RESET_PASSWORD = 'reset password',
  CHANGE_PASSWORD = 'change password',
  LOGIN = 'login',
  CREATE = 'create',
  DELETE = 'delete',
  UPDATE = 'update'
}

export enum ExamState {
  FAILED = -1,
  INCOMPLETE = 0,
  PASSED = 1,
  PRETEST = 2,
  PAYMENT = 3,
  QUALIFICATION_TEST = 4,
  PRACTICE = 5,
  EXPIRED = 8
}

export enum ExamStateEnum {
  FAILED = -1,
  INCOMPLETE = 0,
  PASSED = 1,
  VERIFY_INFO = 2,
  SELF_ASSESSMENT = 3,
  PAYMENT = 4,
  QUALIFICATION_TEST = 5,
  PRACTICE = 6
}

export enum CurrencyEnum {
  USD = 'usd',
  RIEL = 'khr'
}

export enum DocRequirement {
  ID_Card = 1,
  Birth_Certificate = 2
}

export enum QuestionType {
  SINGLE_CHOICE = 1,
  MATCHING = 2
}

export enum PaymentEnum {
  PAID = 1,
  REQUESTING = 3
}

export enum BaseKeyAddressEnum {
  BASE_PROVINCE = 'baseProvince',
  BASE_DISTRICT = 'baseDistrict',
  BASE_COMMUNE = 'baseCommune',
  BASE_VILLAGE = 'baseVillage',
  BASE_CURRENT_PROVINCE = 'baseProvince',
  BASE_CURRENT_DISTRICT = 'baseCurrentDistrict',
  BASE_CURRENT_COMMUNE = 'baseCurrentCommune',
  BASE_CURRENT_VILLAGE = 'baseCurrentVillage'
}

export enum EFileType {
  image = 'image/*',
  pdf = 'application/pdf',
  doc = 'application/msword',
  docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ppt = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  pptx = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  xls = 'application/vnd.ms-excel',
  xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
}
