import { Pipe, PipeTransform } from '@angular/core';
import { Student } from 'src/app/models/student';
import { User } from 'src/app/models/user';

@Pipe({
  name: 'fullName'
})
export class FullNamePipe implements PipeTransform {
  transform(user: User | Student, lang?: string): string {
    let names = [];
    if (user === undefined) return '';

    if (user?.first_name_en && user?.last_name_en && lang === 'en') {
      names.push(user.first_name_en);
      names.push(user.last_name_en);
    } else {
      names.push(user?.last_name);
      names.push(user?.first_name);
    }

    return names.join(' ');
  }

  // transform(value: User | User[], id: string = ''): string {
  //   if (value) {
  //     let user: User | undefined;
  //     if (Array.isArray(value)) {
  //       user = (value as User[]).find(u => u._id == id);
  //     } else {
  //       user = value;
  //     }
  //     if (user) {
  //       let names = [];
  //       if (user.last_name) {
  //         names.push(user.last_name);
  //       }

  //       if (user.first_name) {
  //         names.push(user.first_name);
  //       }
  //       return names.join(' ');
  //     }
  //   }
  //   return '';
  // }
}
