import { animate, state, style, transition, trigger } from '@angular/animations';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { Platform } from '@angular/cdk/platform';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSidenav } from '@angular/material/sidenav';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';
import { Unsubscribe } from 'src/app/helpers/unsubscribe';
import EnumConstant from 'src/app/models/enums/enumConstant';
import { LocalStorageEnum } from 'src/app/models/enums/local-storage.enum';
import { ChildItem, MenuItem } from 'src/app/models/menu-item';
import { AuthService } from 'src/app/services/auth.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ProfileService } from 'src/app/services/profile.service';
import { RoleCheckerService } from 'src/app/services/role-checker.service';
import { UserDataService } from 'src/app/services/user-data.service';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
  animations: [
    trigger('isVisibleChanged', [
      state('true', style({ opacity: 1 })),
      state('false', style({ opacity: 0 })),
      transition('1 => 0', animate('10ms')),
      transition('0 => 1', animate('900ms'))
    ])
  ]
})
export class ContainerComponent extends Unsubscribe implements OnInit {
  menu!: MenuItem[];
  showSubmenu = false;
  isShowing = false;
  showSubSubMenu = false;
  isFirefox: boolean;
  sidebarMode: any;
  mobileQuery!: boolean;
  smallScreen!: boolean;
  authUser!: object;
  isAuth!: boolean;
  account: any;
  activeAcademicYear!: string;
  authSubscribe: any;
  ExamStateEnum = EnumConstant.ExamState;
  // isNotify: boolean = true;
  // menuAdmin!: MenuItem[];
  currentRoute: string;
  exceptRoute = {
    APPLY_FOR_EXAM: '/apply-for-exam'
  };

  mapLink: SafeResourceUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
    'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15635.072195419276!2d104.8945687!3d11.5684789!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x98739cd2adc74cbc!2sMinistry%20of%20Labour%20and%20Vocational%20Training!5e0!3m2!1sen!2skh!4v1670911696352!5m2!1sen!2skh'
  );

  @ViewChild('sidenav') sidenav!: MatSidenav;
  isFullWidth: boolean = false;
  dateNow: Date = new Date();
  isCloseReminder: boolean = false;
  langs = {
    'km': "ខ្មែរ",
    'en': 'English'
  };
  browserLang: string;

  constructor(
    private route: ActivatedRoute,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private profileService: ProfileService,
    private authService: AuthService,
    public router: Router,
    private userDataService: UserDataService,
    private breakpointObserver: BreakpointObserver,
    private roleCheckerService: RoleCheckerService,
    private platform: Platform,
    private translate: TranslateService,
    private localStorageService: LocalStorageService,
  ) {
    super();
    this.isFirefox = platform.FIREFOX;
    this.menu = [
      {
        title: 'menu.dashboard',
        route: '/home',
        icon: 'circle',
        svgIcon: '',
        child: [],
        permissions: []
      },
      {
        title: 'menu.occupation',
        route: '/exam',
        icon: 'circle',
        svgIcon: '',
        child: [],
        permissions: []
      },
      {
        title: 'menu.announcement',
        route: '/announcement',
        icon: 'circle',
        svgIcon: '',
        child: [],
        permissions: []
      },
      {
        title: 'menu.assessment_center',
        route: '/assessment-center',
        icon: 'circle',
        svgIcon: '',
        child: [],
        permissions: []
      }
      // {
      //   title: 'អំពីយើង',
      //   route: '/contact-us',
      //   icon: 'circle',
      //   svgIcon: '',
      //   child: [],
      //   permissions: []
      // }
    ];
    this.matIconRegistry.addSvgIconSet(
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg-icon-set.svg')
    );
    this.matIconRegistry.addSvgIconSet(
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/side-nav-icon-set.svg')
    );
    this.authSubscribe = this.authService.authChange$.subscribe(isAuth => {
      this.isAuth = isAuth;

      if (this.isAuth) {
        this.profileService.getAccountInfo().subscribe(data => {
          this.account = data;
          localStorage.setItem('account', JSON.stringify(data));
          // this.profileService.staffId = data._id;
          // this.localStorageService.setArray(LocalStorageEnum.permissions, data.roles.permissions);
          this.userDataService.changeUserData(data);

          //push my exam route if logged in
          if (this.menu.filter(fil => fil.title === 'menu.my_profile').length === 0) {
            this.menu.push({
              title: 'menu.my_profile',
              route: '/my-exam',
              icon: 'circle',
              svgIcon: '',
              child: [],
              permissions: []
            });
          }
          // this.initSidenav();
        });
      }
    });

    this.browserLang = localStorageService.get(LocalStorageEnum.language) || 'km';

    router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
      this.isFullWidth =
        event.url.includes('login') || event.url.includes('auth') || event.url.includes('home') || event.url === '/'
          ? true
          : false;

      if (event.url.split('/')[1] === 'exam' && event.url.split('/').length > 2) this.isCloseReminder = true;
    });

    this.isFullWidth =
      this.router.url.includes('login') ||
      this.router.url.includes('auth') ||
      this.router.url.includes('home') ||
      this.router.url === '/'
        ? true
        : false;
    this.currentRoute = this.router.url;
  }

  ngOnInit(): void {
    this.onSmallScreen();
    this.breakpointObserver.observe([Breakpoints.Large]).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.mobileQuery = false;
      }
    });
  }

  useLanguage(language: string) {
    this.translate.use(language);
    this.browserLang = language;
    this.localStorageService.set(LocalStorageEnum.language, language);
    // this.authService.authChange$.next(true);
  }

  private initSidenav() {
    this.menu = [];
    for (let i = 0; i < this.menu.length; i++) {
      if (this.menu[i].child.length > 0) {
        let childs: ChildItem[] = [];
        for (let j = 0; j < this.menu[i]?.child.length; j++) {
          if (this.menu[i]?.child[j].permissions.length > 0) {
            if (this.checkPermission(this.menu[i]?.child[j].permissions)) {
              childs.push(this.menu[i].child[j]);
            }
          } else {
            childs.push(this.menu[i].child[j]);
          }
        }

        if (childs.length > 0) {
          let m: MenuItem = JSON.parse(JSON.stringify(this.menu[i]));
          m.child = JSON.parse(JSON.stringify(childs));
          this.menu.push(m);
        }
      } else {
        if (this.menu[i].permissions.length > 0) {
          if (this.checkPermission(this.menu[i].permissions)) {
            this.menu.push(this.menu[i]);
          }
        } else {
          this.menu.push(this.menu[i]);
        }
      }
    }
  }

  private checkPermission(arr: string[]): boolean {
    let perms: string[] = this.roleCheckerService.GetPermissions();

    if (perms == undefined || perms.length <= 0) {
      return false;
    }
    for (let i = 0; i < arr.length; i++) {
      if (perms.filter(e => e == arr[i]).length > 0) {
        return true;
      }
    }
    return false;
  }

  isChildActive(childs: MenuItem[] | ChildItem[]): boolean {
    for (let i = 0; i < childs.length; i++) {
      if (this.router.isActive(this.router.createUrlTree(childs[i].route), false)) {
        return true;
      }
    }
    return false;
  }

  onResize(): void {
    if (window.innerWidth <= 959) {
      this.mobileQuery = true;
    }
    if (window.innerWidth > 959) {
      this.mobileQuery = false;
    }
  }
  onSmallScreen(): void {
    if (window.innerWidth <= 959) {
      this.mobileQuery = true;
    }
    if (window.innerWidth > 959) {
      this.mobileQuery = false;
    }
  }

  toggleSideNav(): void {
    if (this.mobileQuery) {
      this.sidenav.toggle();
    }
  }

  logout(): void {
    this.isAuth = false;
    // this.authSubscribe.unsubscribe();
    this.authService.logout();
    this.router.navigateByUrl('/login');
    localStorage.clear();
    this.account = null;
    this.isCloseReminder = false;

    this.menu = this.menu.filter(fil => fil.title !== 'menu.my_profile');
  }
}
