import { Pipe, PipeTransform } from '@angular/core';
import { Address } from 'src/app/models/address';

@Pipe({
  name: 'fullAddress'
})
export class FullAddressPipe implements PipeTransform {
  /** data | fullAddress: 'medium' */
  transform(value: Address, opt?: string): string {
    if (value && !opt) {
      return `${value?.street} ${value.house_number} ភូមិ${value.villages.name} ឃុំ/សង្កាត់${value.communes.name} ស្រុក/ខណ្ឌ${value.districts.name} ខេត្ត/រាជធានី${value.city_provinces?.name}`;
    } else if (value && opt == 'medium') {
      return `${value.villages?.name || ''}, ${value.communes?.name || ''}, ${value.districts?.name || ''}, ${
        value.city_provinces?.name || ''
      }`;
    } else if (value && opt == 'short') {
      return `ឃុំ/សង្កាត់${value.communes?.name ? value.communes?.name : ''} ស្រុក/ខណ្ឌ${
        value.districts?.name ? value.districts?.name : ''
      } ខេត្ត/រាជធានី${value.city_provinces?.name ? value.city_provinces?.name : ''}`;
    } else if(value && opt == 'translate') {
      return `${value.villages?.name_en || ''}, ${value.communes?.name_en || ''}, ${value.districts?.name_en || ''}, ${
        value.city_provinces?.name_en || ''
      }`;
    }

    return '';
  }
}
