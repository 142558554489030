export const environment = {
  production: false,
  api_url: 'https://erpl-api.myplgs.com',
  file_static_url: 'https://erpl-api.myplgs.com/shared/file_data/download/',
  file_streaming_url: 'https://erpl-api.myplgs.com/shared/file_data/streaming/',
  front_url: 'https://erpl-lms.myplgs.com',

  facebook: {
    appId: '166619338132135',
    clientId: '5af1a32d27be6671856493aeeafae7dd'
  },

  firebase_config: {
    apiKey: 'AIzaSyC00neLyxmJ5y6hIo1QDkkmTJqS0WGeVK4',
    authDomain: 'erpl-66b1a.firebaseapp.com',
    projectId: 'erpl-66b1a',
    storageBucket: 'erpl-66b1a.appspot.com',
    messagingSenderId: '951622319252',
    appId: '1:951622319252:web:1ab26d18a6287e72524881',
    measurementId: 'G-6J7GJ2SMRQ'
  }
};
